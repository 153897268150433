<template>
  <div class="h-full content-bg">
    <div v-show="!config.isDetailDisplay" :class="config.isDetailDisplay ? 'small-list' : 'scroll-auto'">
      <Control :config="config" @onChangeEditDataSource="onChangeEditDataSource" @shuju="shuju"></Control>
    </div>
   <div v-show="config.isDetailDisplay" class="detail">
      <EditControl v-if="showEditControl" :dataSource="editDataSource" :config="config" :Carriers="Carriers">
      </EditControl> 
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        Carriers: {},
        showEditControl: false,
        config: {
          isDetailDisplay: false,
          moduleName: 'LogOrgan',
          moduleEvent:{
              initial:'LogOrgan:PageList',
              add:'LogOrgan:Add'
          }
        },
        editDataSource: {},
        initialDataSource: {}
      }
    },
    mounted() {
      this.Utils.lazy(() => {
        this.showEditControl = true;
      }, 500);
    },
    methods: {
      shuju: function (data) {
        this.Carriers = data.Result;
      },
      onChangeEditDataSource: function (dataSource) {
        this.editDataSource = dataSource;
        this.initialDataSource = this.Utils.extend({}, dataSource);
      },
      syncDataSource(dataSource) {
        this.editDataSource = dataSource;
      },
      checkSaveStatus() {
        this.config.isDetailDisplay = false;
      }
    },
    components: {
      "EditControl": resolve => { require(['./components/edit.vue'], resolve) },
      "Control": resolve => { require(['./components/list.vue'], resolve) },
    }
  }
</script>